exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ansok-om-konto-js": () => import("./../../../src/pages/ansok-om-konto.js" /* webpackChunkName: "component---src-pages-ansok-om-konto-js" */),
  "component---src-pages-apply-for-an-account-js": () => import("./../../../src/pages/apply-for-an-account.js" /* webpackChunkName: "component---src-pages-apply-for-an-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-data-set-collection-compare-js": () => import("./../../../src/templates/dataSetCollection-compare.js" /* webpackChunkName: "component---src-templates-data-set-collection-compare-js" */),
  "component---src-templates-data-set-collection-list-js": () => import("./../../../src/templates/dataSetCollection-list.js" /* webpackChunkName: "component---src-templates-data-set-collection-list-js" */),
  "component---src-templates-data-set-collection-single-js": () => import("./../../../src/templates/dataSetCollection-single.js" /* webpackChunkName: "component---src-templates-data-set-collection-single-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/markdown-page.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */)
}

